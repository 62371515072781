<template>
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: row; background-color: white; align-items: center">
      <div class="topNav" id="topNav">
        <ul id="topNavUl">
          <li v-for="(i, index) in title" :key="index" :ref="'card-' + index" @click="goCenter(i.id, index, $event, i)">
            <div style="display: flex; flex-direction: column; align-items: center">
              <div :class="selectindex === index ? 'imagback' : 'unimagback'"><img class="img" :src="i.icon" /></div>
              <span :class="selectindex === index ? 'title-sty' : 'untitle-sty'">{{ i.name }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div @click="showAll" style="
          max-width: 1rem;
          padding: 0 0.8rem;
          height: 100%;
          background-color: white;
          align-items: center;
          font-size: 0.8rem;
        ">
        全部<van-icon size="1.1rem" name="wap-nav" style="margin-left: -0.1rem" />
      </div>
    </div>
    <div v-if="show" class="pop" :style="{ top: type == 2 ? '6.5rem' : '9rem' }">
      <div style="max-height: 19rem; overflow-y: scroll">
        <van-grid :border="false" :column-num="5">
          <van-grid-item v-for="(i, index) in title" :key="index">
            <div style="display: flex; flex-direction: column; align-items: center" @click="clickCard(index, true)">
              <div class="wbimagback"><img class="img" :src="i.icon" /></div>
              <span :class="selectindex === index ? 'title-sty' : 'noctitle-sty'">{{ i.name }}</span>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div @click="clossPop"
        style="width: 100%; text-align: center; padding-top: 1rem; font-size: 0.8rem; color: #aaaaaa">
        点击收起<van-icon size="1rem" style="margin-left: 0.5rem" name="arrow-up" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: Array
    },
    index: {
      type: Number,
      default: -1
    },
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      selectindex: this.index,
      currentTopNav: '',
      show: false,
      getDate: true
    }
  },
  created() { },
  components: {},
  methods: {
    clossPop() {
      this.show = false
    },
    showAll() {
      this.show = true
      this.$emit('clossSecondPop')
    },
    clickCard(index, getDate) {
      this.getDate = getDate
      setTimeout(() => {
        if (index >= 0) {
          this.$refs[('card-' + index)][0].click();
        } else {
          this.selectindex = index
        }
      }, 200);
    },
    indexTo(index, getDate) {
      this.getDate = getDate
      this.$refs[('card-' + index)][0].click();
    },
    jumpExternalPage(jumpPageFlag) {
      this.$api
        .getEntrance(jumpPageFlag)
        .then((res) => {
          if (res.code === 0) {
            window.location.href = res.data;
          } else {
            this.$Toast.fail(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goCenter(id, index, e, record) {
      if (record && record.jumpPageFlag) {
        this.jumpExternalPage(record.jumpPageFlag);
        return;
      }
      if (this.show) {
        this.show = false
      }
      this.selectindex = index
      let destination = index - 2;
      destination = destination < 0 ? 1 : destination;
      this.currentTopNav = id;
      if (this.navScroll) {
        this.navScroll.scrollToElement(
          document.querySelector('#topNav li:nth-child(' + destination + ')')
        );
      }
      this.getdata(index);
      // 横向滑动居中
      let ul = document.querySelector('#topNavUl');
      let nav = document.getElementById("topNav");
      let window_offsetWidth = window.innerWidth; //屏幕宽度;
      let dom = e.target;
      if (dom && ul) {
        let domoffsetWidth = dom.offsetLeft,
          //中间值 =( 屏幕宽度 - li宽度 ) / 2;
          diffWidth = (window_offsetWidth - dom.offsetWidth) / 2,
          //目标值 = offset - 中间值
          targetOffset = -(domoffsetWidth - diffWidth);
        let ul_width = ul.getBoundingClientRect().width;//开始

        // 未超出中间值
        if (-targetOffset < 0) {
          nav.scrollLeft = 0;
          return;
        }
        //末尾
        if (targetOffset < window_offsetWidth - ul_width) {
          nav.scrollLeft = -(window_offsetWidth - ul_width);
          return;
        }
        //正常
        nav.scrollLeft = -targetOffset
      }
    },
    getdata(index) {
      if (this.getDate) {
        this.$emit('scrollToAll', index)
      } else
        this.getDate = true
    }

  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.topNav {
  background: #fff;
  display: flex;
  flex: 1;
  overflow: scroll;
  white-space: nowrap;

  ul {
    display: inline-block;
    white-space: nowrap;

    li {
      display: inline-block;
      font-size: 1rem;
      padding: 0 0.1rem;

      &:first-child {}

      &:last-child {}
    }
  }
}

.img {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
}

.imagback {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #edc392 2px solid;
  background: #ece8e2;
  border-radius: 2rem;
}

::v-deep .van-grid-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem 0;
  background-color: #f8f8f8;
}

.unimagback {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #fff 2px solid;
  border-radius: 2rem;
}

.wbimagback {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 2rem;
}

.title-sty {
  // border-radius: 1rem;
  // background-color: #D59A54;
  color: #d59a54;
  font-size: 0.85rem;
  margin: 0 0.5rem;
  margin-top: 0.5rem;
  padding: 0.04rem 0rem 0.15rem 0rem;
  border-bottom: 1px solid #d59a54;
}

.untitle-sty {
  border-radius: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ffffff;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  padding: 0.04rem 0.5rem 0.15rem 0.5rem;
}

.noctitle-sty {
  border-radius: 1rem;
  background-color: #f8f8f8;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  padding: 0.04rem 0.5rem 0.15rem 0.5rem;
}

.pop {
  width: 100%;
  position: absolute;
  top: 9rem;
  overflow-y: scroll;
  z-index: 9999;
  background-color: #f8f8f8;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  padding-bottom: 1rem;
}
</style>
